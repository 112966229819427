{
    "previous_coms": "Pozri predchádzajúce komentáre",
    "next_coms": "Pozri nasledujúce komentáre",
    "ago": "{{duration}} späť",
    "groups": "Skupiny",
    "login_placeholder": "email",
    "password_placeholder": "heslo",
    "new_password_placeholder": "nové heslo",
    "confirm_password_placeholder": "potvrdiť heslo",
    "connect": "Connection",
    "submit": "Odoslať",
    "title_placeholder": "Nadpis vášho príspevku (nie je povinné)",
    "text_placeholder": "Tu napíš príspevok",
    "upload_image": "Pridať fotografie",
    "upload_video": "Pridať videá",
    "upload_music": "Pridať hudbu",
    "upload_pdf": "Pridať pdf",
    "add_date": "Pridať dátum",
    "logout": "Odhlásiť",
    "settings": "Nastavenia",
    "name": "Meno",
    "name_input": "Zvoľ si nejaké meno",
    "login": "Login",
    "login_input": "Zvoľ si nejaké prihlasovacie meno",
    "email": "Email",
    "email_input": "Napíš tvoj email sem",
    "save_changes": "Uložiť zmeny",
    "password": "Heslo",
    "password_input": "Tu napíš heslo",
    "Invalid login/password": "Nesprávny login alebo heslo",
    "edit": "Upraviť",
    "delete": "Zmazať",
    "ask_delete_message": "Si si istý, že chceš zmazať tento príspevok?",
    "account": "Účet",
    "groups": "Skupiny",
    "forgot_password": "Zabudol si svoje heslo?",
    "password_reset_mail_sent": "Email s informáciami o nastavení nového hesla bol odoslaný!",
    "User not found": "Neexistujúci užívateľ",
    "reset_password_title": "Zmena hesla",
    "error": "Chyba",
    "passwords_dont_match": "Heslá sa nezhodujú",
    "invitation_link": "Pozývací odkaz",
    "reset_invitation_link": "Reset invitation link",
    "invitation_welcome": "Vitajte v Zusam ! Zaregistrujte sa pred pripojením sa ku skupine.",
    "signup": "Prihlásiť",
    "create_a_group": "Vytvoriť skupinu",
    "create_the_group": "Vytvoriť skupinu",
    "quit_group": "Opustiť skupinu",
    "settings_updated": "Vaše nastavenia boli zmenené",
    "group_updated": "Nastavenia skupiny boli zmenené",
    "group_left": "Opustili ste skupinu",
    "multiple_photos_upload": "Objavila sa chyba. Skúste priložiť obrázky po jednom (táto chyba sa môže objavovať v niektorých prehliadačoch)",
    "error_new_message": "Pri odosielaní príspevku sa objavila chyba",
    "error_upload": "Objavila sa chyba počas odosielania",
    "cancel_write": "Chcete naozaj zrušiť vytváranie nového príspevku?",
    "empty_message": "Prázdna správa nemôže byť odoslaná",
    "cancel": "Zrušiť",
    "just_now": "Práve teraz",
    "notification_emails": "Notifikačný email",
    "none": "Žiadne",
    "hourly": "Každú hodinu",
    "daily": "Denne",
    "weekly": "Týždenne",
    "monthly": "Mesačne",
    "notification_emails_stopped": "E-mailové upozornenia sa zastavili!",
    "group_share_choice": "Do ktorej skupiny chcete odoslať túto správu ?",
    "public_link": "verejné spojenie",
    "default_group": "Predvolenú skupinu",
    "destroy_account": "Odstráňte svoj účet",
    "destroy_account_explain": "Po odstránení účtu sa už nevráti",
    "are_you_sure": "Si si istý ?",
    "share_message": "zdieľajte správu",
    "users": "užívatelia",
    "has_posted_in": "zverejnila v",
    "has_commented_on": "komentoval",
    "has_joined": "sa pripojil k",
    "has_left": "opustil",
    "the_message_from": "správa od",
    "in": "v",
    "lang": "jazyk",
    "reply": "odpoveď",
    "replies_one": "{{count}} odpoveď",
    "replies_other":"{{count}} odpovedí",
    "publish_in_group": "publikovať v skupine",
    "search_in_group": "hľadať v tejto skupine",
    "changed_group_name": "zmenil názov skupiny",
    "to": "na",
    "notifications": "oznámenia",
    "mark_all_as_read": "označiť všetko ako prečítané",
    "search_without_result": "Vášmu vyhľadávaniu nezodpovedá žiadna správa",
    "add_bookmark": "Túto správu uložiť medzi záložky",
    "remove_bookmark": "Zrušte označenie tejto správy",
    "bookmarks": "záložky",
    "api_key": "Kľúč API",
    "reset_api_key": "Obnovte kľúč rozhrania API (budete odpojení)",
    "video_not_ready": "Video ešte nie je pripravené",
    "bookmark_added": "Správa pridaná do záložiek !",
    "bookmark_removed": "Správa bola odstránená zo záložiek !",
    "parent_message": "Správa rodiča"

}
